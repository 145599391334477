import $ from 'jquery';
import 'bootstrap';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import 'lightbox2/dist/css/lightbox.min.css';
import 'lightbox2';
import 'waypoints/lib/jquery.waypoints.min.js';

jQuery(document).ready(function($) {
  const $hamburger = $('.hamburger');
  const $mobileMenu = $('#mobile-menu');
  const $menuOverlay = $('#mobile-menu-overlay');
  const $menuItems = $('#mobile-menu .menu-item-has-children > a, #mobile-menu .menu-item-has-children > button'); 
  let isFirstSubmenuOpened = false;

  function toggleMenu() {
    $hamburger.toggleClass('open');
    $mobileMenu.toggleClass('open');
    $menuOverlay.toggleClass('open');

    if (!$mobileMenu.hasClass('open')) {
      $('#mobile-menu .sub-menu').css('transform', 'translateX(100%)').removeClass('open');
      isFirstSubmenuOpened = false;
      $('#mobile-menu .menu-item-has-children').removeClass('open');
      $('.open-arrow').hide();
      $('.closed-arrow').show();
    }
  }

  $hamburger.on('click', toggleMenu);
  $menuOverlay.on('click', toggleMenu);

  $menuItems.on('click', function(e) {
    e.preventDefault();
    const $parentItem = $(this).parent();
    const $submenu = $parentItem.children('.sub-menu').first();

    $parentItem.siblings().find('.open-arrow').hide();
    $parentItem.siblings().find('.closed-arrow').show();
    $parentItem.siblings().find('.sub-menu').css('transform', 'translateX(100%)').removeClass('open');

    if ($submenu.hasClass('open')) {
      if ($submenu.css('top') === '46px') {
        $submenu.css('top', '');
        isFirstSubmenuOpened = false;
      }
      $submenu.removeClass('open');
      $submenu.css('transform', 'translateX(100%)');
    } else {
      if (!isFirstSubmenuOpened && $('body').hasClass('admin-bar') && $parentItem.parent().is('.menu')) {
        $submenu.css('top', '46px');
        isFirstSubmenuOpened = true;
      }
      $submenu.addClass('open');
      $submenu.css('transform', 'translateX(0)');
    }
  });

  $('.mobile-menu .sub-menu').each(function() {
    const $submenu = $(this);
    const backButtonText = $('#back-button-text').text();
    const $backBtn = $('<li class="back"><button type="button">' + backButtonText + '</button></li>'); 
    $submenu.prepend($backBtn);
    $backBtn.on('click', function(e) {
      e.preventDefault();
      const $parentItem = $submenu.parent();
      const $openArrow = $parentItem.find('.open-arrow').first();
      const $closedArrow = $parentItem.find('.closed-arrow').first();

      if ($submenu.css('top') === '46px') {
        $submenu.css('top', '');
        isFirstSubmenuOpened = false;
      }
      $submenu.css('transform', 'translateX(100%)');
      setTimeout(() => {
        $submenu.removeClass('open');
        $parentItem.removeClass('open');
        $openArrow.hide();
        $closedArrow.show();
      }, 300);
    });
  });
});

$(document).ready(function() {
  const dropdowns = $('.menu-item').not('.mobile-menu .menu-item');
  let timer;

  dropdowns.each(function() {
    const dropdown = $(this);
    const dropdownButton = dropdown.find('a, button'); 
    const dropdownMenu = dropdown.find('.dropdown-menu');

    dropdown.on('mouseenter', function() {
      clearTimeout(timer);
      $('.dropdown-menu').removeClass('open').fadeOut(300);
      dropdown.addClass('open');
      dropdownMenu.stop(true, true).fadeIn(300).addClass('open');
    });

    dropdown.on('mouseleave', function() {
      timer = setTimeout(function() {
        dropdown.removeClass('open');
        dropdownMenu.stop(true, true).fadeOut(300).removeClass('open');
      }, 1000);
    });

    dropdownButton.on('click', function() {
      var href = $(this).attr('href');
      if (href) {
        window.location.href = href;
      }
    });
  });

  $('.menu-item a, .menu-item button').not('.mobile-menu .menu-item a, .mobile-menu .menu-item button').on('click', function(e) {
    e.preventDefault();
    $('.menu-item a, .menu-item button').removeClass('active-link');
    $(this).addClass('active-link');
    $('.active-list').hide();
    var linkClass = $(this).attr('class').split(' ').filter(cls => cls.startsWith('link'))[0];
    var target = $('.active-list').filter('.' + linkClass);
    target.show();
    checkActiveList();
  });

  $('.sub-cat-toggle').on('click', function(e) {
    e.preventDefault();
    $('.menu-item a, .menu-item button').removeClass('active-link');
    $(this).addClass('active-link');
    $('.sub-cat').hide();
    var targetClass = $(this).data('target');
    $('.' + targetClass).toggle();
    checkActiveList();
  });

  function checkActiveList() {
    $('.dropdown-box').each(function() {
      var $this = $(this);
      var anyVisible = $this.find('.active-list').is(':visible');
      var allHidden = !$this.find('.active-list').is(':visible');

      if (anyVisible) {
        $this.find('ul').each(function() {
          var $ul = $(this);
          if ($ul.find('.sub-cat').length > 0 || $ul.find('.sub-cat-toggle').length > 0) {
            $ul.addClass('cat-menu-border');
          }
        });
      } else if (allHidden) {
        $this.find('ul').each(function() {
          var $ul = $(this);
          if ($ul.find('.sub-cat').length > 0 || $ul.find('.sub-cat-toggle').length > 0) {
            $ul.removeClass('cat-menu-border');
          }
        });
      }
    });
  }

  checkActiveList();
});

$(document).ready(function() {
  $('.dropdown-menu').each(function() {
    var $this = $(this);
    if ($this.find('.sub-cat').length > 0 || $this.find('.sub-cat-toggle').length > 0) {
      $this.addClass('dropdown-menu-padding-sub-cat');
    } else {
      $this.addClass('dropdown-menu-padding-no-sub');
    }
  });
});

$(document).ready(function() {
  let navbar = $('.nav-bar');
  let logo = $('.nav-bar .banner-logo');

  function checkScroll() {
    if ($(window).scrollTop() > 100) {
      logo.addClass('visible');
      navbar.addClass('shadow');
    } else {
      logo.removeClass('visible');
      navbar.removeClass('shadow');
    }
  }

  $(window).on('scroll', function() {
    checkScroll();
  });

  checkScroll();
});

jQuery(document).ready(function($) {
  $('#mobile-menu .menu-item-has-children > a, #mobile-menu .menu-item-has-children > button').on('click', function(event) {
    event.preventDefault();
    var $parentItem = $(this).parent();
    var $openArrow = $parentItem.find('.open-arrow');
    var $closedArrow = $parentItem.find('.closed-arrow');
    var $subMenu = $parentItem.find('.sub-menu').first();

    if ($parentItem.hasClass('open')) {
      $parentItem.removeClass('open');
      $openArrow.first().hide();
      $closedArrow.first().show();
      $subMenu.css('transform', 'translateX(100%)');
    } else {
      $parentItem.addClass('open');
      $openArrow.first().show();
      $closedArrow.first().hide();
      $subMenu.css('transform', 'translateX(0)');
    }
  });
});

// Moduły

$(document).ready(function() {
  if ($('.timeline').length) {
    import('../../dist/js/timeline.min.js')
      .then(() => {
        console.log('Timeline załadowany!');
      })
      .catch(err => {
        console.error('Błąd ładowania Timeline:', err);
      });
  }

  if ($('.truck').length) {
      import('./truck-animation')
          .then(module => {
              module.default();
          })
          .catch(err => {
              console.error('Błąd ładowania animacji ciężarówki:', err);
          });
  }

  if ($('.content-switcher').length) {
      import('./content-switcher')
          .then(module => {
              module.default();
          })
          .catch(err => {
              console.error('Błąd ładowania przełączania treści:', err);
          });
  }

  if ($('#logo-carousel').length) {
      import('./owl-carousel-logo')
          .then(module => {
              module.default();
          })
          .catch(err => {
              console.error('Błąd ładowania Owl Carousel:', err);
          });
  }

  if ($('.question').length) {
      import('./faq')
          .then(module => {
              module.default();
          })
          .catch(err => {
              console.error('Błąd ładowania FAQ:', err);
          });
  }

  if ($('#load-more').length) {
    import('./comics-ajax')
        .then(module => {
            module.default();
        })
        .catch(err => {
            console.error('Błąd ładowania komiksów:', err);
        });
  }

  if ($('#load-more-guides').length) {
      import('./guides-ajax')
          .then(module => {
              module.default();
          })
          .catch(err => {
              console.error('Błąd ładowania poradników:', err);
          });
  }

  if ($('#load-more-posts').length) {
      import('./posts-ajax')
          .then(module => {
              module.default();
          })
          .catch(err => {
              console.error('Błąd ładowania postów:', err);
          });
  }

  if ($('#contact-tabs').length) {
      import('./contact-tabs')
          .then(module => {
              module.default();
          })
          .catch(err => {
              console.error('Błąd ładowania zakładek kontaktu:', err);
          });
  }

  if ($('#job-offers-btn-czechowice').length || $('#job-offers-btn-piotrkow').length) {
      import('./job-offers-tabs')
          .then(module => {
              module.default();
          })
          .catch(err => {
              console.error('Błąd ładowania zakładek ofert pracy:', err);
          });
  }

  if ($('.services-container').length) {
    import('./agency-services')
        .then(module => {
            module.default();
        })
        .catch(err => {
            console.error('Błąd ładowania kafelków agencji:', err);
        });
  }

  if ($('.timeline').length) {
      import('./about-us')
          .then(module => {
              module.default();
          })
          .catch(err => {
              console.error('Błąd ładowania zakładki O nas:', err);
          });
  }

  if ($('#about-slider').length || $('#reference-slider').length) {
      import('./owl-carousel-about')
          .then(module => {
              module.default();
          })
          .catch(err => {
              console.error('Błąd ładowania Owl Carousel na stronie O nas:', err);
          });
  }

  if ($('.counter').length) {
    import('./transport-counter')
        .then(module => {
            module.default();
        })
        .catch(err => {
            console.error('Błąd ładowania licznika transportu:', err);
        });
  }

  if ($('.countries-partner-container').length) {
      import('./country-partners')
          .then(module => {
              module.default();
          })
          .catch(err => {
              console.error('Błąd ładowania sekcji Kraj:', err);
          });
  }

  if ($('#map-container').length) {
      import('./map-tooltip')
          .then(module => {
              module.default();
          })
          .catch(err => {
              console.error('Błąd ładowania mapy:', err);
          });
  }

  if ($('#country-slider .owl-carousel').length) {
      import('./country-slider')
          .then(module => {
              module.default();
          })
          .catch(err => {
              console.error('Błąd ładowania slidera krajów:', err);
          });
  }

  if ($('#back-to-top').length) {
    import('./back-to-top')
        .then(module => {
            module.default();
        })
        .catch(err => {
            console.error('Błąd ładowania przycisku back-to-top:', err);
        });
  }

  if ($('.flip-card').length) {
      import('./equal-height-flip-cards')
          .then(module => {
              module.default();
          })
          .catch(err => {
              console.error('Błąd ładowania flip-card:', err);
          });
  }
});

$(document).ready(function () {
  $(document).on('initialized.owl.carousel', function(event) {
    updateAriaLabels($(event.target));
  });

  function updateAriaLabels(carousel) {
    carousel.find('.owl-dot').each(function (index) {
      $(this).attr('aria-label', 'Przejdź do slajdu: ' + (index + 1));
    });

    carousel.find('.owl-prev').attr('aria-label', 'Poprzedni slajd');
    carousel.find('.owl-next').attr('aria-label', 'Następny slajd');
  }

  $('a').each(function () {
    if (!$(this).attr('aria-label')) {
      $(this).attr('aria-label', 'Odnośnik');
    }
  });

  $('button').each(function () {
    if (!$(this).attr('aria-label')) {
      $(this).attr('aria-label', 'Przycisk');
    }
  });
});